<template>
  <b-overlay
    id="overlay-background"
    :show="loading"
    variant="transparent"
    :opacity="0.9"
    blur="true"
    rounded="sm"
  >
    <b-card class="card">
      <div class="row-flex-align-center row-flex-justify-space-between card__header">
        <span class="tw-font-bold text-white tw-text-xl">
          {{ $t('Notifications') }}
        </span>
        <b-form-checkbox
          v-if="settings.length"
          v-model="activeNotifcation"
          switch
          @input="toggleStatus"
        />

      </div>

      <div class="card__body">
        <table v-if="settings.length" class="card__body__table">
          <tr>
            <th />
            <th>{{ $t('Push') }}</th>
            <th>{{ $t('Email') }}</th>
          </tr>
          <tr v-for="(setting,index) in settings" :key="index">
            <td>
              {{ title(setting.display) }}
            </td>
            <td>
              <feather-icon
                size="18"
                :icon="setting.webSocket ? 'CheckSquareIcon' : 'SquareIcon'"
                :class="{ 'tw-text-primary': setting.webSocket, 'checkbox-disable-color' :!activeNotifcation, 'cursor-pointer' : activeNotifcation }"
                @click="activeNotifcation ? setting.webSocket = !setting.webSocket : null"
              />
            </td>
            <td>
              <feather-icon
                size="18"
                :icon="setting.email ? 'CheckSquareIcon' : 'SquareIcon'"
                :class="{ 'tw-text-primary': setting.email, 'checkbox-disable-color' :!activeNotifcation, 'cursor-pointer' : activeNotifcation }"
                @click="activeNotifcation ? setting.email = !setting.email : null"
              />
            </td>
          </tr>
        </table>
        <div v-else class="text-center tw-mt-4">{{ $t(`You don't have notification settings yet`) }}</div>
      </div>

      <div class="card__action">
        <b-button
          class="mr-1"
          variant="primary"
          :disabled="saving || $lodash.isEqual(settings, initialSettings)"
          @click="saveChanges"
        >
          <span>{{ $t("Save Changes") }}</span>
        </b-button>
        <b-button variant="outline-primary" class="tw-mr-4" :disabled="$lodash.isEqual(settings, initialSettings)" @click="resetFromPreviousSetting">
          <span class="align-middle">{{ $t("Cancel") }}</span>
        </b-button>

      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import notificationProfile from '@/services/notificationProfile'
import { title } from '@/@core/utils/filter'

export default {

  name: 'ProfileNotification',
  data() {
    return {
      loading: false,
      activeNotifcation: true,
      settings: [],
      saving: false,
      initialSettings: [],
      title,
    }
  },
  created() {
    this.fetchNotificationSettings()
  },
  methods: {
    async saveChanges() {
      this.saving = true

      const notificationSetting = this.settings.map(setting => ({
        category: setting.category,
        subcategory: setting.subcategory,
        webSocket: setting.webSocket,
        email: setting.email,
      }))

      const { errors } = await this.$async(notificationProfile.updateNotificationSettings({ notificationSetting }))
      if (!errors) this.initialSettings = this.$lodash.cloneDeep(this.settings)
      this.saving = false
    },
    async fetchNotificationSettings() {
      this.loading = true
      const { response } = await this.$async(notificationProfile.getAllNotificationSettings())

      const settingResponse = response.data.map(item => ({
        ...item,
        display: `${item.subcategory} - ${item.category} `,
      }))

      this.activeNotifcation = response.data[0]?.active ?? false

      this.settings = this.$lodash.cloneDeep(settingResponse)
      this.initialSettings = this.$lodash.cloneDeep(settingResponse)
      this.loading = false
    },

    resetFromPreviousSetting() {
      this.settings = this.$lodash.cloneDeep(this.initialSettings)
    },

    async toggleStatus(val) {
      this.loading = true
      await this.$async(notificationProfile.toggleNotificationStatus(val ? 'on' : 'off'))
      this.loading = false
    },
  },

}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/master-variables.scss';
.card {
  position: relative;
  min-height: 400px;
  padding-bottom: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    border-bottom: 1px solid var(--colour--input-border);
    padding-bottom: 1rem
  }

  &__body {
    &__table {
      width: 100%;

    }
    color: var(--colour--text-body);
  }

  &__action {
    right: 15px;
    display: flex;
    position: absolute;
    bottom: 15px
  }

}

.checkbox-disable-color {
  color: var(--colour--primary);
  opacity: 0.5
}

</style>
